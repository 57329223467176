﻿import "bootstrap";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "jquery-ajax-unobtrusive";

import "@fortawesome/fontawesome-free/js/fontawesome"
import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/regular"
import "@fortawesome/fontawesome-free/js/brands"

import _ from "lodash";

import $ from "jquery";
window.jQuery = $;
window.$ = $;

$.validator.setDefaults({ ignore: null });

import "bootstrap/dist/css/bootstrap.css";
//import "~bootstrap/scss/bootstrap";
import "../css/site.css";

import "@progress/kendo-ui";
import "@progress/kendo-ui/js/kendo.aspnetmvc";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "@progress/kendo-ui/js/cultures/kendo.culture.en-AU";

kendo.culture(navigator.language);
